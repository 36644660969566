@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/elevation';
@use '$styles/mediaqueries';
@use '$styles/values';

.topAdjustment {
  @include mediaqueries.smallUp {
    &:global(.top) {
      padding-top: calc(80px + 44px) !important;
      margin-top: -80px;
    }
  }
  @include mediaqueries.largeUp {
    &:global(.top) {
      padding-top: 80px !important;
      margin-top: -80px;
    }
  }
}

@include mediaqueries.extraSmallOnly {
  .stickyCTA {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: elevation.$top;
    padding: 20px 16px;
    background-color: colors.$white;
    box-shadow: 0 1px 16px 0 rgba(0 0 0 / 0.25);
    a {
      width: 100%;
    }
  }
}

// Transforms the paragraphs in Contentful in new lines in our outputted HTML
.newLine:first-child {
  display: none;
}

// new nav sticky behaviour
:global(.sticky-nav) {
  .intersector {
    height: 0; // height of the promo banner - probably needs to be a positive value of at least 1px?
    width: 100%;
    position: relative;
    left: 0;
    z-index: -100;
    visibility: hidden;
  }
  
  :global(.flipPromoBanner) {
    + main {
      .intersector {
        height: 0;
        @include mediaqueries.mediumUp {
          height: 60px;
        }
        
        @include mediaqueries.smallUp {
          height: 56px;
          top: 0;
        }
      }
    }
  }
}
